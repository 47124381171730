import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import CoreContainer from "../core-container";
import CustomLink from "../link";

const BigText = ({ data = {} }) => {
  const {
    link,
    theme,
    anchor,
    bodyText,
    mainText,
    subTitle,
    moduleWidth,
    mainTextHTMLTag,
    bodyTextHTMLTag,
    subTitleHTMLTag,
  } = data;

  const mainTextTag = mainTextHTMLTag?.value || "p";
  const bodyTextTag = bodyTextHTMLTag?.value || "p";
  const subTitleTag = subTitleHTMLTag?.value || "p";
  const isFullWidth = moduleWidth?.size === "fullWidth";

  return (
    <CoreContainer
      id={anchor}
      theme={theme?.value}
      mobilePadding={false}
      isFullWidth={isFullWidth}
      containerClassName={cx(anchor ? "scroll-mt-32" : null)}
    >
      <section className="mx-auto py-12 px-8 text-center md:py-14">
        {subTitle &&
          React.createElement(
            subTitleTag,
            {
              className: "mt-2 text-md font-semibold uppercase tracking-normal text-accent-700",
            },
            <>{subTitle}</>
          )}
        {React.createElement(
          mainTextTag,
          {
            className: "py-4 pt-0 text-4xl font-normal lg:text-5xl",
          },
          <>{mainText}</>
        )}
        {bodyText &&
          React.createElement(
            bodyTextTag,
            {
              className: "mx-auto mb-4 text-lg font-light md:max-w-2xl ",
            },
            <>{bodyText}</>
          )}
        <CustomLink {...link} />
      </section>
    </CoreContainer>
  );
};

BigText.propTypes = {
  data: PropTypes.shape({
    mainText: PropTypes.string,
    mainTextHTMLTag: PropTypes.shape({
      _type: PropTypes.string,
      value: PropTypes.string,
    }),
    subTitle: PropTypes.string,
    subTitleHTMLTag: PropTypes.shape({
      _type: PropTypes.string,
      value: PropTypes.string,
    }),
    bodyText: PropTypes.string,
    bodyTextHTMLTag: PropTypes.shape({
      _type: PropTypes.string,
      value: PropTypes.string,
    }),
    link: PropTypes.shape({
      shouldScroll: PropTypes.bool,
      newWindow: PropTypes.bool,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    moduleWidth: PropTypes.shape({
      size: PropTypes.string,
    }),
    theme: PropTypes.shape({
      value: PropTypes.oneOf(["light", "soft", "dark"]),
    }),
  }),
};

export default BigText;
